import React from 'react';

import { Header } from '../../components/layouts/HeaderComponent';
import { Footer } from '../../components/layouts/Footer';
import { PageContainer } from '../../components/containers/PageContainer';
import { HelmetContainer } from '../../components/pageComponents/helmet';
import { LargeHero } from '../../components/V2/LargeHero/LargeHero';
import { SplitColumn } from '../../components/V2/SplitColumn/SplitColumn';
import { BackgroundGrey } from '../../GlobalStyles';
import { HighlightBlockNoImage } from '../../components/V2/HighlightBlocks/HighlightBlockNoImage/HighlightBlockNoImage';
import { ArticleBlock } from '../../components/V2/ArticleBlock/ArticleBlock';
import { TextGrid } from '../../components/V2/TextGrid/TextGrid';

const CompanyPageJSON = JSON.stringify({
  '@context': 'https://schema.org',
  '@type': 'Corporation',
  name: 'Oqcam',
  url: 'https://oqcam.com/',
  logo: 'https://oqcam.com/assets/images/DarkOqcam.svg',
  sameAs: [
    'https://www.linkedin.com/company/oqcam/',
    'https://oqcam.com/'
  ]
});

const Company = () => {
  const Lang = 'de';
  const data = require(`../../../data/content/Pages/company/${Lang}.json`);
  const globalCopy = require(`../../../data/content/Global/${Lang}.json`);

  return (
    <PageContainer>
      <HelmetContainer currentPage={'company'} lang={Lang} title={data.helmet.title} json={CompanyPageJSON} />
      <Header lang={Lang} />

      <LargeHero
        Background={'/assets/images/pages/partners/partner-bg.webp'}
        BackgroundStyle={'cover'}
        Copy={data.content.Hero.title}
        Button={{
          copy: globalCopy.Navigation.Contact,
          link: globalCopy.Navigation.FooterURLs.contact
        }}
      />

      <SplitColumn
        Copy={data.content.Split.Copy}
        TextSize={'S'}
        Background={'White'}
        ImageOverlap={false}
        ImageBackground={false}
        Image={{
          src: '/assets/images/pages/company/our-vision.png',
          alt: ''
        }}
        Button={{
          theme: 'stripped',
          copy: data.content.Split.Button,
          link: '/posts/how-oqcams-ai-driven-automation-accelerates-manufacturing/'
        }}
      />
      <SplitColumn
        Copy={data.content.Split2.Copy}
        TextSize={'S'}
        ImagePosition={'Left'}
        Background={'White'}
        ImageOverlap={false}
        ImageBackground={false}
        Image={{
          src: '/assets/images/pages/company/autonomous-manufacturing.png',
          alt: ''
        }}
      />

      <SplitColumn
        Copy={data.content.Split3.Copy}
        TextSize={'S'}
        Background={'White'}
        ImageOverlap={false}
        ImageBackground={false}
        Image={{
          src: '/assets/images/pages/company/Oqcam-circle.png',
          alt: ''
        }}
      />

      <BackgroundGrey>
        <TextGrid Title={data.content.Grid.title} Layout={'layout-2'} Items={data.content.Grid.items} />
      </BackgroundGrey>

      <HighlightBlockNoImage
        Theme={'Dark-Primary'}
        Title={data.content.HighlightBlock.title}
        Button={{
          copy: data.content.HighlightBlock.button,
          link: globalCopy.Navigation.FooterURLs.careers
        }}
      />

      <ArticleBlock Title={data.content.Articles.title} Layout={'layout-3'} MostRecentBlogs={true} />

      <Footer lang={Lang} />
    </PageContainer>
  );
};

export default Company;
